import React from "react";
import {
	HashRouter,
	Route,
	Redirect,
	Switch,
	// browserHistory
} from "react-router-dom";
// import { Router, browserHistory } from 'react-router';
// import { createHashHistory } from "history";
import history from "../util/history";
import { PrivateRoute } from "./privateRoute";
// Components
import Login from "../containers/Login/Login";
import Dashboard from "../containers/Dashboard/Dashboard";
import Categories from "../containers/Categories/Categories";
import ManageArtWorks from "../containers/ManageArtworks/ManageArtworks";
import ManageUsers from "../containers/ManageUsers/ManageUsers";
import ManageArtists from "../containers/ManageArtists/ManageArtists";
import ManageGallery from "../containers/ManageGallery/ManageGallery";
import ManageHome from "../containers/ManageHome/ManageHome";
import UserProfile from "../containers/ManageUsers/UserProfile/UserProfile";
import Collections from "../containers/Collections/Collections";
import Orders from "../containers/Orders/Orders";
import OrderStatus from "../containers/Orders/OrderStatus/OrderStatus";
import Reports from "../containers/Reports/Reports";
import AddBrands from "../containers/AddBrands/AddBrands";
import MessageContainer from "../components/MessageContainer/MessageContainer";
import ChangePassword from "../containers/Password/ChangePassword/ChangePassword";
import OtpVerification from "../containers/Password/OtpVerification/OtpVerification";
import ForgotPassword from "../containers/Password/ForgotPassword/ForgotPassword";
import Notification from "../containers/Notification/Notification";
import BlogIndex from "../containers/More/Blog/BlogIndex";
import News from "../containers/More/News/News";
import Report from "../containers/More/Report/Report";
import UserOrderHistory from "../containers/ManageUsers/UserOrderHistory/UserOrderHistory";
import EditProduct from "../containers/EditProduct/EditProduct";
import EditProductEditProductMetaData from "../containers/EditProduct/EditProductMetaData";
import CountriesTax from "../containers/CountriesTax/CountriesTax";

import Quotes from "../containers/Quotes/Quotes";
import OfflineServices from "../containers/OfflineServices/OfflineServices";
import TwoFactor from "../containers/TwoFactor/TwoFactor";
import ShipmentProvider from "../containers/ShipmentProvider/ShipmentProvider";
import ShippingCharges from "../containers/ShippingCharges/ShippingCharges";
import Lookup from "../containers/Lookup/Lookup";
import FilterRules from "../containers/FilterRules/FilterRules";
import AddFilterRule from "../containers/FilterRules/AddFilter/AddFilterRule";
import UpdateFilterRule from "../containers/FilterRules/AddFilter/UpdateFilterRule";
import BulkUpload from "../containers/BulkUpload/BulkUpload";
import Coupons from "../containers/ManageCoupon/Coupon";
import AuctionListing from "../containers/Auctions/Auctions";
import AuctionDetails from "../containers/Auctions/AuctionDetails/AuctionDetails.js";
import AuctionLot from "../containers/Auctions/AuctionLot/AuctionLot";
import AuctionSummary from "../containers/Auctions/AuctionSummary/AuctionSummary";
import EditAuctionLot from "../containers/Auctions/AuctionLot/EditAuctionLot/EditAuctionLot.js";
import AuctionNftCollection from "../containers/Auctions/AuctionDetails/AuctionNftColletion/AuctionNftCollection";
import AbandonedCartReports from "../containers/AbandonedCartReports/AbandonedCartReports";
import UserSearchHistory from "../containers/UserSearchHistory/UserSearchHistory";
//Survey
import SurveyForms from "../containers/SurveyForms/SurveyForms";
import CreateNewSurvey from "../containers/SurveyForms/CreateNewSurvey";
import PreviewSurvey from "../containers/SurveyForms/PreviewSurvey";
import ViewSurveyAnswer from "../containers/SurveyForms/ViewSurveyAnswer";
import PageAnalytics from "../containers/Analytics/PageAnalytics";
import ChangeUserRole from "../containers/ManageUsers/ChangeUserRole";
import ReelsVideos from "../containers/ReelsVideos/ReelsVideos";
import ArtistProfile from "../containers/ManageArtists/EditProfile";
import CreateNewArtist from "../containers/ManageArtists/AddArtists/CreateNewArtist"

// SERVER SIDE ROUTE: HashRouter uses a hash symbol in the URL,
// which has the effect of all subsequent URL path content being ignored in the server request
// (ie you send "www.mywebsite.com/#/person/john" the server gets "www.mywebsite.com".
// As a result the server will return the pre # URL response, and
// then the post # path will be handled by parsed by your client side react application.
export const AppRoutes = () => {
	return (
		<HashRouter history={history}>
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/forgot-password" component={ForgotPassword} />
				<Route exact path="/otp-verification/:id?" component={OtpVerification} />
				<Route exact path="/change-password/:id?" component={ChangePassword} />
				<Route exact path="/two-factor/:id?" component={TwoFactor} />

				<PrivateRoute exact path="/dashboard" component={Dashboard} />
				<PrivateRoute exact path="/categories/:id" component={Categories} />
				<PrivateRoute exact path="/categories" component={Categories} />
				<PrivateRoute exact path="/manage-home" component={ManageHome} />

				<PrivateRoute exact path="/filter" component={FilterRules} />
				<PrivateRoute exact path="/filter/new-filter" component={AddFilterRule} />
				<PrivateRoute exact path="/filter/edit-filter/:filterId" component={UpdateFilterRule} />

				<PrivateRoute exact path="/manage-artworks/:pageNum" component={ManageArtWorks} />
				<Route exact path="/manage-artworks" render={() => <Redirect to="/manage-artworks/1" />} />
				<PrivateRoute exact path="/offline-services/:pageNum" component={OfflineServices} />
				<Route exact path="/offline-services" render={() => <Redirect to="/offline-services/1" />} />
				<PrivateRoute exact path="/edit-artwork/:slugId/:stockId/:pageNum" component={EditProduct} />
				<PrivateRoute exact path="/edit-artwork-metadata/:slugId/:stockId/:pageNum" component={EditProductEditProductMetaData} />

				<PrivateRoute exact path="/countries-taxes/:pageNum" component={CountriesTax} />
				<Route exact path="/countries-taxes" render={() => <Redirect to="/countries-taxes/1" />} />

				<PrivateRoute exact path="/shipment-providers/:pageNum" component={ShipmentProvider} />
				<Route exact path="/shipment-providers" render={() => <Redirect to="/shipment-providers/1" />} />

				<PrivateRoute exact path="/shipping-charges/:pageNum" component={ShippingCharges} />
				<Route exact path="/shipping-charges" render={() => <Redirect to="/shipping-charges/1" />} />

				<PrivateRoute exact path="/quotes/:pageNum" component={Quotes} />
				<Route exact path="/quotes" render={() => <Redirect to="/quotes/1" />} />

				<PrivateRoute exact path="/manage-users/user-profile/:id?" component={UserOrderHistory} />
				<PrivateRoute exact path="/manage-users/:pageNum" component={ManageUsers} />
				<Route exact path="/manage-users" render={() => <Redirect to="/manage-users/1" />} />

				<PrivateRoute exact path="/manage-artists/:pageNum" component={ManageArtists} />
				<PrivateRoute exact path="/add-artist" component={CreateNewArtist} />
				<Route exact path="/manage-artists" render={() => <Redirect to="/manage-artists/1" />} />
				<PrivateRoute exact path="/manage-artists/user-profile/:id" component={UserProfile} />
				<PrivateRoute exact path="/artist/profile/edit/:id" component={ArtistProfile} />

				<PrivateRoute exact path="/manage-gallery/:pageNum" component={ManageGallery} />
				<Route exact path="/manage-gallery" render={() => <Redirect to="/manage-gallery/1" />} />
				<PrivateRoute exact path="/manage-gallery/user-profile/:id" component={UserProfile} />

				<PrivateRoute exact path="/reports" component={Reports} />
				<PrivateRoute exact path="/manage-brand" component={AddBrands} />
				<PrivateRoute exact path="/notification" component={Notification} />
				<PrivateRoute exact path="/collections" component={Collections} />

				<PrivateRoute exact path="/survey-forms" component={SurveyForms} />
				<PrivateRoute exact path="/new-survey" component={CreateNewSurvey} />
				<PrivateRoute exact path="/preview-survey/:surveyId" component={PreviewSurvey} />
				<PrivateRoute exact path="/survey-answers/:surveyId/:pageNum" component={ViewSurveyAnswer} />

				<PrivateRoute exact path="/orders/order-status/:id?" component={OrderStatus} />
				<PrivateRoute exact path="/orders/:pageNum" component={Orders} />
				<Route exact path="/orders" render={() => <Redirect to="/orders/1" />} />
				<PrivateRoute exact path="/blog/:slugId" component={BlogIndex} />
				<PrivateRoute exact path="/news" component={News} />
				{/* survey pages  */}
				<PrivateRoute exact path="/survey-forms" component={SurveyForms} />
				<PrivateRoute exact path="/new-survey" component={CreateNewSurvey} />
				<PrivateRoute exact path="/preview-survey/:surveyId" component={PreviewSurvey} />
				<PrivateRoute exact path="/survey-answers/:surveyId/:pageNum" component={ViewSurveyAnswer} />

				<PrivateRoute exact path="/report" component={Report} />

				<PrivateRoute exact path="/lookup" component={Lookup} />
				<PrivateRoute exact path="/bulk-artworks-upload" component={BulkUpload} />
				<PrivateRoute exact path="/coupons" component={Coupons} />
				<PrivateRoute exact path="/auctions" component={AuctionListing} />
				<PrivateRoute exact path="/auctions/details/:id?" component={AuctionDetails} />
				<PrivateRoute exact path="/auctions/lot/:auctionId/:rank/:artworkType" component={AuctionLot} />
				<PrivateRoute exact path="/auctions/summary/:auctionId" component={AuctionSummary} />
				<PrivateRoute exact path="/auctions/edit-lot/:lotId/:startDate/:endDate" component={EditAuctionLot} />
				<PrivateRoute exact path="/auctions/details/:id?" component={AuctionDetails} />
				<PrivateRoute exact path="/auctions/nft/:auctionId/:type/:contractAddress?" component={AuctionNftCollection} />
				<PrivateRoute exact path="/abandoned-carts" component={AbandonedCartReports} />
				<PrivateRoute exact path="/search-history" component={UserSearchHistory} />
				<PrivateRoute exact path="/analytics" component={PageAnalytics} />
				<PrivateRoute exact path="/user-requests" component={ChangeUserRole} />
				<PrivateRoute exact path="/reels-video" component={ReelsVideos} />

				<Route path="/:message" component={MessageContainer} />
				<Route exact path="/" render={() => <Redirect to="/login" />} />
			</Switch>
		</HashRouter>
	);
};
