import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Select, Popover, Icon, Tooltip } from "antd";
import { isEmpty, isArray } from "lodash";
//Files
import {
	getArtworksListAction,
	updateProductStatusAction,
	deleteArtworkAction,
	adminDownloadProductAction,
	setArtworkFeaturedAction,
	submitArtworktoBlockchain,
	unHoldArtworkAction,
	modifyArtworkStateAction,
	getArtistNamesAction,
} from "../../redux/actions/ManageArtworksAction";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
// SCSS
import "./ManageArtworks.scss";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import { getAllArtists } from "../../redux/actions/CollectionAction";
import FilterDropdown from "../Collections/AddCollectionDialog/FilterDropdown/FilterDropdown";
import { Checkbox } from "antd";
import propertiesList from "../../properties.json";
import moment from "moment";
import { authorizedGet } from "../../util/apiConfig";
import ViewEditions from "./ViewEditions";
import { ArtistTabSwitchWrapper, ArtistTabs } from "../ManageArtists/ManageArtist.styled";

const { Option } = Select;
const baseUrl = propertiesList.fontEndUrl;
class ManageArtWorks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			markAsReserveAuctionconfirmationDialog: false,
			deleteconfirmationDialog: false,
			mintTokenconfirmationDialog: false,
			holdDialog: false,
			unHoldDialog: false,
			markAsSoldDialog: false,
			searchValue: "",
			pageNumValue: 1,
			filterValue: null,
			filterLabel: "All",
			stockId: "",
			selectedRecordToPerformAction: null,
			status: null,
			currentData: null,
			orderSort: null,
			orderOption: null,
			minPrice: 1,
			maxPrice: 9999999,
			minOffer: 0,
			maxOffer: 100,
			minOfferPrice: 0,
			maxOfferPrice: 9999999,
			selectedArtists: [],
			artistName: "",
			IsFeatured: null,
			allLiaisonsList: [],
			selectedLiaison: null,
			showEditionListTable: false,
			editionArtworkId: "",
			artworkId: "",
			artistsList: []
		};
	}

	componentDidMount() {
		// List Sellers by fetching details from URL - When Page Loads
		this.getArtistNames();
		this.getLiaisonsList();
		if (localStorage.getItem("isEditArtworkPageVisited") && localStorage.getItem("isEditArtworkPageVisited") === "true") {
			let requestObj =
				localStorage.getItem("artworkFilterOptions") !== undefined ? JSON.parse(localStorage.getItem("artworkFilterOptions")) : [];
			this.props.getArtworksListAction(requestObj);
			localStorage.setItem("isEditArtworkPageVisited", false);
		} else {
			this.setSearchParams();
		}
	}

	componentDidUpdate(prevProps) {
		// List Sellers by fetching details from URL - When URL change occur
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.setSearchParams();
		}
	}
	onChangeIsFeatured(e) {
		this.setState(
			{
				IsFeatured: e.target.checked,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
		console.log(`checked = ${e.target.checked}`);
	}
	getLiaisonsList = () => {
		authorizedGet("UserManagment/api/Artists/GetAllLiaisons").then((res) => {
			if (res.resultCode === 1) {
				this.setState({
					allLiaisonsList: res.response && res.response.data,
				});
			}
		});
	};
	onChangeFilterLiaisons = (val) => (event) => {
		this.setState(
			{
				selectedLiaison: event,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};

	render() {
		const options = { year: "numeric", month: "long", day: "numeric" };

		const FilterList = [
			{ label: "All", value: null },
			{ label: "Available", value: 1 },
			{ label: "Available Resell", value: 2 },
			{ label: "Sold Out", value: 3 },
			{ label: "Pending With Artist", value: 4 },
			{ label: "Pending With Admin", value: 5 },
			{ label: "BlockChain Pending", value: 6 },
			{ label: "Placed For Resell", value: 7 },
			{ label: "ReSell Rejected", value: 8 },
			{ label: "Deleted", value: 9 },
			{ label: "Blocked", value: 10 },
			{ label: "Sold Out Externally", value: 11 },
			{ label: "Order Processing", value: 12 },
			{ label: "NFC Pending", value: 13 },
			{ label: "Hold Artworks", value: 14 },
			{ label: "Pending With Gallery", value: 15 },
			{ label: "Reserve For Auction", value: 16 },
			{ label: "Auction Visible", value: 17 },
			{ label: "Auction Live", value: 18 },
			{ label: "User Account On Hold", value: 19 },
		];

		const columns = [
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend") return <Tooltip title="Click to cancel sort">Products</Tooltip>;
					else if (sortOrder === "ascend") return <Tooltip title="Click sort by descend">Products</Tooltip>;
					else return <Tooltip title="Click sort by ascend">Products</Tooltip>;
				},
				className: "sorter-no-tooltip",
				dataIndex: "productName",
				key: "productName",
				width: 200,
				render: (text, record) => (
					<a
						href={`${baseUrl}art/${record.medium && record.medium.slugId}/${record.slugId}?isAdminCheck=true`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<span className="colWrapper">
							{record.status !== 3 && new Date(record.creationDate).toLocaleDateString() === new Date().toLocaleDateString() && (
								<img className="labelImage" src={require("../../assets/images/common/New_Label.svg")} alt="label" />
							)}
							<img
								className="colImage"
								src={record.displayPicture ? record.displayPicture : require("../../assets/images/common/No_Image.png")}
								alt="productImage"
							/>
							<div className="colDiv">
								<Tooltip title={text ? text : "Nil"}>
									<p className="colText">{text ? text : "Nil"}</p>
								</Tooltip>
								<p className="colGreyText">{record.oneLineDescription ? record.oneLineDescription : "Nil"}</p>
							</div>
						</span>
					</a>
				),
				sorter: true,
				sortDirections: ["ascend", "descend"],
				width: 200
			},
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend") return <Tooltip title="Click to cancel sort">Artist</Tooltip>;
					else if (sortOrder === "ascend") return <Tooltip title="Click sort by descend">Artist</Tooltip>;
					else return <Tooltip title="Click sort by ascend">Artist</Tooltip>;
				},
				className: "sorter-no-tooltip",
				dataIndex: "artistName",
				key: "artistName",
				render: (text) => (
					<div className="artistDiv">
						<img src={require("../../assets/images/common/User.svg")} alt="user" />
						<Tooltip title={text ? text : "Nil"}>
							<p>{text ? text : "Nil"}</p>
						</Tooltip>
					</div>
				),
				sorter: true,
				sortDirections: ["ascend", "descend"],
				width: 200,
			},
			{
				title: "VIEW COUNT",
				dataIndex: "viewCount",
				key: "viewCount",
				render: (text) => text,
				width: 140,
			},
			{
				title: "MEDIUM",
				dataIndex: "medium",
				key: "medium",
				render: (text) => text && text.name,
				width: 150,
			},
			{
				title: "UPLOAD DATE",
				dataIndex: "creationDate",
				key: "creationDate",
				width: 190,
				render: (text) => moment(text).format("DD MMM YYYY"),
			},
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend") return <Tooltip title="Click to cancel sort">LAST UPDATE DATE</Tooltip>;
					else if (sortOrder === "ascend") return <Tooltip title="Click sort by descend">LAST UPDATE DATE</Tooltip>;
					else return <Tooltip title="Click sort by ascend">LAST UPDATE DATE</Tooltip>;
				},
				dataIndex: "updatedDate",
				key: "lastUpdateDate",
				render: (text) => moment(text).format("DD MMM YYYY"),
				sorter: true,
				sortDirections: ["ascend", "descend"],
				width: 190
			},
			{
				title: "LIAISON",
				dataIndex: "liaison",
				key: "liaison",
				width: 200,
				sorter: true,
				sortDirections: ["ascend", "descend"],
			},
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend") return <Tooltip title="Click to cancel sort">Price</Tooltip>;
					else if (sortOrder === "ascend") return <Tooltip title="Click sort by descend">Price</Tooltip>;
					else return <Tooltip title="Click sort by ascend">Price</Tooltip>;
				},
				className: "sorter-no-tooltip",
				dataIndex: "price",
				key: "price",
				render: (text) => <p className="textBlack">&#36;{text && text.toLocaleString("en-IN")}</p>,
				sorter: true,
				sortDirections: ["ascend", "descend"],
			},

			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend") return <Tooltip title="Click to cancel sort">Status</Tooltip>;
					else if (sortOrder === "ascend") return <Tooltip title="Click sort by descend">Status</Tooltip>;
					else return <Tooltip title="Click sort by ascend">Status</Tooltip>;
				},
				className: "sorter-no-tooltip",
				key: "status",
				dataIndex: "status",
				render: (text) => {
					return (
						<div>
							{text === 1 && <p className="textBlue">Available</p>}
							{text === 2 && <p className="textBlue">Available Resell</p>}
							{text === 3 && <p className="textGreen">Sold out</p>}
							{text === 4 && <p className="textDarkBlue">Pending With Artist</p>}
							{text === 5 && <p className="textDarkBlue">Pending With Admin</p>}
							{text === 6 && <p className="textDarkBlue">BlockChain Pending</p>}
							{text === 7 && <p className="textBrown">Placed For Resell</p>}
							{text === 8 && <p className="textRed">ReSell Rejected</p>}
							{text === 9 && <p className="textRed">Deleted</p>}
							{text === 10 && <p className="textRed">Blocked</p>}
							{text === 11 && <p className="textRed">Sold out externally</p>}
							{text === 12 && <p className="textRed">Order Processing</p>}
							{text === 13 && <p className="textRed">NFC Pending</p>}
							{text === 14 && <p className="textRed">Artwork On Hold</p>}
							{text === 15 && <p className="textRed">Pending With Gallery</p>}
							{text === 16 && <p className="textRed">Reserved For Auction</p>}
							{text === 17 && <p className="textRed">Auction Visible</p>}
							{text === 18 && <p className="textRed">Auction Live</p>}
							{text === 19 && <p className="textRed">User Account On Hold</p>}
						</div>
					);
				},
				sorter: true,
				sortDirections: ["ascend", "descend"],
				width: 200,
			},
			{
				title: "Sub Status",
				dataIndex: "subStatus",
				key: "subStatus",
				width: 200,
				render: (text) => {
					return (
						<div>
							{text === 0 && <p className="textBlue">None</p>}

							{text === 1 && <p className="textDarkBlue">BlockChain Pending</p>}
							{text === 2 && <p className="textDarkBlue">Ready To Go Blockchain</p>}
							{text === 3 && <p className="textDarkBlue">Added To Blockchain</p>}
						</div>
					);
				},
			},
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend") return <Tooltip title="Click to cancel sort">Featured</Tooltip>;
					else if (sortOrder === "ascend") return <Tooltip title="Click sort by descend">Featured</Tooltip>;
					else return <Tooltip title="Click sort by ascend">Featured</Tooltip>;
				},
				className: "sorter-no-tooltip",
				key: "isFeatured",
				dataIndex: "isFeatured",
				render: (text, record) => {
					if (!text) {
						return (
							<div className="imageWrapper">
								<img
									className="star-image"
									src={require("../../assets/images/common/Star.svg")}
									onClick={this.onFeatured.bind(this, record)}
									alt="star"
								/>
							</div>
						);
					} else {
						return (
							<div className="imageWrapper">
								<img
									className="star-image"
									src={require("../../assets/images/common/Star_Red.svg")}
									onClick={this.onUnFeatured.bind(this, record)}
									alt="star"
								/>
							</div>
						);
					}
				},
				sorter: true,
				sortDirections: ["ascend", "descend"],
			},
			{
				title: "Approved Date",
				dataIndex: "approvedDate",
				key: "approvedDate",
				render: (text, record) => <p className="">{text ? moment(text).format("DD MMM YYYY") : "Nill"}</p>,
				// sorter: true,
				// sortDirections: ['ascend', 'descend'],
			},
			{
				title: "LE/Orginal",
				dataIndex: "isLimitedEdition",
				key: "isLimitedEdition",
				render: (text, record) => <p className="">{text === true ? "Limited Edition" : "Orginal"}</p>,
				width: 160,
			},
			{
				title: "Edition Count",
				dataIndex: "stock",
				key: "stock"
			},
			{
				title: "Action",
				key: "action",
				fixed: "right",
				render: (record) =>
					record.status !== 9 && record.status !== 19 && (
						<Popover
							placement="bottom"
							content={content(record)}
							overlayClassName="manage-artworks-action-popover"
							onVisibleChange={this.onVisibleChangeOfArtworkRecord.bind(this, record)}
						>
							<img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
						</Popover>
					),
			},
		];
		const content = (record) => (
			<div>
				{<span className="action-span" onClick={this.toggleViewEditionTable.bind(this, record)}>
					<Icon type="wallet" />
					<p className="pop-item">View Editions</p>
				</span>}
				{(record.status === 5 || record.status === 7 || record.status === 10 || record.status === 8) && (
					<span
						className="action-span"
						onClick={
							record.status === 7 || record.status === 8
								? this.toggleApproveConfirmationDialog.bind(this, record, 2)
								: this.toggleApproveConfirmationDialog.bind(this, record, 1)

						}
					>

						<Icon type="check" />
						<p className="pop-item">Approve</p>
					</span>
				)}
				{(record.status === 1 || record.status === 2 || record.status === 4 || record.status === 5 ||
					record.status === 7 || record.status === 8 || record.status === 13 || record.status === 15 || record.status === 16) && (
						<span
							className="action-span"
							onClick={
								this.toggleHoldConfirmationDialog.bind(this, record, 14)
							}
						>

							<Icon type="check" />
							<p className="pop-item">Hold Artwork</p>
						</span>
					)}
				{(record.status === 14) && (
					<span
						className="action-span"
						onClick={
							this.toggleUnHoldConfirmationDialog.bind(this, record)
						}
					>

						<Icon type="check" />
						<p className="pop-item">UnHold Artwork</p>
					</span>
				)}
				{(record.status === 5 || record.status === 7) && (
					<span
						className="action-span"
						onClick={
							record.status === 7
								? this.toggleRejectConfirmationDialog.bind(this, record, 8)
								: this.toggleRejectConfirmationDialog.bind(this, record, 10)
						}
					>

						<Icon type="close" />
						<p className="pop-item">Block</p>
					</span>
				)}
				{record.status !== 9 && (
					<div className="action-span" onClick={this.onClickEditArtwork.bind(this)}>
						<img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
						<p className="pop-item">Edit</p>
					</div>
				)}
				{/* {record.isLimitedEdition === false && record.status !== 9 && record.status !== 11 && record.status !== 3 && record.status !== 10 &&
					record.status !== 16 && record.status !== 17 && record.status !== 18 && (
						<div className="action-span" onClick={this.toggleMarkAsSoldConfirmationDialog.bind(this, record, 11)}>
							<Icon type="check" />
							<p className="pop-item">Mark As Sold</p>
						</div>
					)} */}
				{record.status !== 9 && (
					<span className="action-span" onClick={this.toggleDeleteConfirmationDialog.bind(this, record, 9)}>
						<img src={require("../../assets/images/common/delete.svg")} alt="delete" />
						<p className="pop-item">Delete</p>
					</span>
				)}
				{record.subStatus === 1 && (
					<span className="action-span" onClick={this.toggleMintTokenConfirmationDialog.bind(this, record)}>
						<Icon type="wallet" />
						<p className="pop-item">Mint token</p>
					</span>
				)}
				{record.status === 1 && (
					<span className="action-span" onClick={this.toggleReserveForAuctionConfirmationDialog.bind(this, record, 16)}>
						<Icon type="wallet" />
						<p className="pop-item">Reserve For Auction</p>
					</span>
				)}
			</div>
		);
		const { artworksListLoader, savedArtworksList, savedArtworkListCount, artworksDownloadLoader } = this.props.manageArtworksReducer;
		// const { artistsList } = this.props.collectionReducer;
		const { allLiaisonsList } = this.state;

		return (
			<Root>
				<Topbar title={"Manage Artworks"} showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />
				<div className="manageartworks-wrapper">
					<ArtistTabSwitchWrapper>
						<ArtistTabs active={this.state.filterValue === null} onClick={this.setCurrentTab.bind(this, null)}>ALL</ArtistTabs>
						<ArtistTabs active={this.checkArrayEquals(this.state.filterValue, ["5"])} onClick={this.setCurrentTab.bind(this, 5)}>PENDING ADMIN</ArtistTabs>
						<ArtistTabs active={this.checkArrayEquals(this.state.filterValue, ["4", "13"])} onClick={this.setCurrentTab.bind(this, ["4", "13"])}>PENDING ARTIST</ArtistTabs>
						<ArtistTabs active={this.checkArrayEquals(this.state.filterValue, ["14", "16"])} onClick={this.setCurrentTab.bind(this, ["14", "16"])}>PARKED</ArtistTabs>
						<ArtistTabs active={this.checkArrayEquals(this.state.filterValue, ["1"])} onClick={this.setCurrentTab.bind(this, 1)}>AVAILABLE</ArtistTabs>
					</ArtistTabSwitchWrapper>
					<div className="filter-wrapper">
						{/* <img className="filter-img" src={require("../../assets/images/common/Filter.svg")} alt="filter" /> */}
						<Select className="filter-field" value={this.state.filterLabel} onChange={this.onChangeFilter(this)}>
							{FilterList &&
								FilterList.map((item, index) => (
									<Option key={index} value={item.value}>
										{item.label}
									</Option>
								))}
						</Select>
						<div className="artist-search">
							<input
								type="text"
								className="search-box-field"
								placeholder="Artist Alias Name"
								value={this.state.artistName}
								onChange={this.onChangeArtistSearch("artistName")}
							/>
						</div>
						<FilterDropdown
							selectedList={this.state.selectedArtists}
							checkboxList={this.state.artistsList}
							idToFetch="userId"
							nameToDisplay="alaisName"
							filterFor="Artist"
							returnBackQueryArray={this.onSetArtistArray.bind(this)}
						/>
						{/* <FilterSlider
							filterFor="Price"
							title="Price"
							defaultMinValue={1}
							defaultMaxValue={9999999}
							minValue={this.state.minPrice}
							maxValue={this.state.maxPrice}
							returnBackPriceObj={this.onSetPrice.bind(this)}
						/>
						<FilterSlider
							filterFor="Offer"
							title="Offer"
							defaultMinValue={0}
							defaultMaxValue={100}
							minValue={this.state.minOffer}
							maxValue={this.state.maxOffer}
							returnBackPriceObj={this.onSetOffer.bind(this)}
						/>
						<FilterSlider
							filterFor=" Offer Price"
							title=" Offer Price"
							defaultMinValue={0}
							defaultMaxValue={9999999}
							minValue={this.state.minOfferPrice}
							maxValue={this.state.maxOfferPrice}
							returnBackPriceObj={this.onSetOfferPrice.bind(this)}
						/> */}
						<Select className="filter-field" value={this.state.selectedLiaison} onChange={this.onChangeFilterLiaisons(this)}>
							<Option value={null}>Liaisons</Option>
							{allLiaisonsList &&
								allLiaisonsList.map((item, index) => (
									<Option key={index} value={item}>
										{item}
									</Option>
								))}
						</Select>
						<div className="checkbox-wrapper">
							<Checkbox onChange={this.onChangeIsFeatured.bind(this)}>Is Featured</Checkbox>
						</div>
						<button className="btn-orange" onClick={this.downloadArtworks.bind(this)} disabled={artworksDownloadLoader}>
							Export to Excel
							{artworksDownloadLoader && <CircularProgress size={24} className="login-progress" />}
						</button>
					</div>

					<div className="sub-wrapper">
						{savedArtworksList && savedArtworksList.length !== 0 ? (
							<Table
								rowKey={(record) => record.rfid}
								columns={columns}
								dataSource={savedArtworksList}
								pagination={false}
								showSorterTooltip
								onChange={this.onChangeForSort.bind(this)}
								loading={artworksListLoader}
								scroll={{ x: 2400 }}
								sticky
							/>
						) : artworksListLoader ? (
							<CircularProgress className="circular-loader" size={30} />
						) : (
							<div className="empty-table">No Artworks Found.</div>
						)}
						{!artworksListLoader && (
							<Pagination
								current={parseInt(this.state.pageNumValue)}
								total={savedArtworkListCount}
								pageSize={10}
								onChange={this.onSelectPageNumber(this)}
							/>
						)}
					</div>
				</div>
				<ConfirmationMessageDialog
					open={this.state.approveconfirmationDialog}
					handleClose={this.toggleApproveConfirmationDialog.bind(this)}
					confirmMessage={
						"Choose 'Reserved for Auction' if this artwork is to list in the auction, else Choose 'Make Available' to make this artwork available for purchase."
					}
					onConfirm={this.onApproveArtwork.bind(this)}
					confirmButton={"Make Available"}
					additionalButton={"Reserve for Auction"}
					onClickAdditionButton={this.onClickReserveArtworkForAuction.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.markAsReserveAuctionconfirmationDialog}
					handleClose={this.toggleReserveForAuctionConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to reserve this artwork for auction?"}
					onConfirm={this.onClickReserveArtworkForAuction.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.rejectconfirmationDialog}
					handleClose={this.toggleRejectConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to block this artwork?"}
					onConfirm={this.onRejectArtwork.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.markAsSoldDialog}
					handleClose={this.toggleMarkAsSoldConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to mark as sold this artwork?"}
					onConfirm={this.onUpdateArtworkStatus.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.deleteconfirmationDialog}
					handleClose={this.toggleDeleteConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to delete this artwork?"}
					onConfirm={this.onDeleteArtwork.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.mintTokenconfirmationDialog}
					handleClose={this.toggleMintTokenConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to push to block chain"}
					onConfirm={this.onMintToken.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.unHoldDialog}
					handleClose={this.toggleUnHoldConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to UnHold this artwork?"}
					onConfirm={this.onUnHoldArtwork.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.holdDialog}
					handleClose={this.toggleHoldConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to Hold this artwork?"}
					onConfirm={this.onUpdateArtworkStatus.bind(this)}
				/>
				{this.state.showEditionListTable === true && <ViewEditions
					show={this.state.showEditionListTable}
					artworkId={this.state.editionArtworkId}
					onCloseCouponDrawer={this.toggleHideEditionTable.bind(this)}
				/>}
			</Root>
		);
	}

	checkArrayEquals = (array1, array2) => {
		if (array1 && array2) {
			return array1.toString() === array2.toString();
		}
		return false;
	}

	onChangeForSort(pagination, filters, sorter) {
		let sortOptionData = [
			{ label: "productName", value: 1 },
			{ label: "artistName", value: 2 },
			{ label: "stock", value: 3 },
			{ label: "price", value: 4 },
			{ label: "discountPercentage", value: 5 },
			{ label: "sellingPrice", value: 6 },
			{ label: "status", value: 7 },
			{ label: "isFeatured", value: 8 },
			{ label: "liaison", value: 9 },
			{ label: "createDate", value: 10 },
			{ label: "lastUpdateDate", value: 11 },
		];
		if (!isEmpty(sorter)) {
			let findItem = sortOptionData.find((element) => element.label === sorter.columnKey);
			if (sorter && sorter.order) {
				this.setState(
					{
						orderSort: sorter.order === "descend" ? 1 : 0,
						orderOption: findItem.value,
					},
					() => {
						this.doChangeUrlWithQuery();
					}
				);
			} else {
				this.setState(
					{
						orderSort: null,
						orderOption: null,
					},
					() => {
						this.doChangeUrlWithQuery();
					}
				);
			}
		} else {
			this.setState(
				{
					orderSort: null,
					orderOption: null,
				},
				() => {
					this.doChangeUrlWithQuery();
				}
			);
		}
	}

	setCurrentTab(value) {
		this.setState({
			filterValue: value,
			pageNumValue: 1,

		}, () => {
			this.doChangeUrlWithQuery();
		})
	}

	onChangeArtistSearch = (name) => (event) => {
		this.setState({ artistName: event.target.value }, () => {
			this.doChangeUrlWithQuery();
		});
	};
	// Set Price
	onSetPrice = (min, max) => {
		this.setState(
			{
				minPrice: min,
				maxPrice: max,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};
	// Set Offer
	onSetOffer = (min, max) => {
		this.setState(
			{
				minOffer: min,
				maxOffer: max,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};
	// Set Offer Price
	onSetOfferPrice = (min, max) => {
		this.setState(
			{
				minOfferPrice: min,
				maxOfferPrice: max,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};
	// Set Artists Array
	onSetArtistArray = (list) => {
		this.setState(
			{
				selectedArtists: list,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};

	// ------------------------ When Page loads/ URL changes ------------------------- //
	// Call API by fetching details from URL
	setSearchParams() {
		const { location } = this.props;
		let FilterList = [
			{ label: "All", value: null },
			{ label: "Available", value: 1 },
			{ label: "Available Resell", value: 2 },
			{ label: "Sold Out", value: 3 },
			{ label: "Pending With Artist", value: 4 },
			{ label: "Pending With Admin", value: 5 },
			{ label: "BlockChain Pending", value: 6 },
			{ label: "Placed For Resell", value: 7 },
			{ label: "ReSell Rejected", value: 8 },
			{ label: "Deleted", value: 9 },
			{ label: "Blocked", value: 10 },
			{ label: "Sold Out Externally", value: 11 },
			{ label: "Order Processing", value: 12 },
			{ label: "NFC Pending", value: 13 },
			{ label: "Pending With Gallery", value: 15 },
			{ label: "Reserve For Auction", value: 16 },
			{ label: "Auction Visible", value: 17 },
			{ label: "Auction Live", value: 18 },
			{ label: "User Account On Hold", value: 19 },
		];

		// When filter/Search params exist
		if (location.search) {
			let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
			let filteredItem = FilterList.find(({ value }) => value === +parsedQuery.Filter);
			console.log("🚀 ~ file: ManageArtworks.js:697 ~ ManageArtWorks ~ setSearchParams ~ parsedQuery.IsFeatured", parsedQuery.IsFeatured);

			this.setState(
				{
					filterValue: parsedQuery.Filter,
					filterLabel: filteredItem && filteredItem.label ? filteredItem.label : "All",
					searchValue: parsedQuery.Search,
					pageNumValue: this.props.match.params.pageNum,
					selectedArtists: !isEmpty(parsedQuery.ArtistId) ? this.stringToArray(parsedQuery.ArtistId) : [],
					minPrice: parseInt(parsedQuery.MinPrice),
					maxPrice: parseInt(parsedQuery.MaxPrice),
					minOffer: parseInt(parsedQuery.OfferMinPercentage),
					maxOffer: parseInt(parsedQuery.OfferMaxPercentage),
					artistName: parsedQuery.ArtistName,
					IsFeatured:
						parsedQuery.IsFeatured !== null && parsedQuery.IsFeatured !== undefined
							? parsedQuery.IsFeatured === "true"
								? true
								: false
							: null,
					orderOption: parsedQuery.SortOption ? parseInt(parsedQuery.SortOption) : 0,
					orderSort: parsedQuery.SortOrder ? parseInt(parsedQuery.SortOrder) : 0,
					selectedLiaison: parsedQuery.Liaison,
				},

				() => {
					this.doSearchFilterSellers(parsedQuery);
				}
			);
		}

		// When there is no filter/Search params
		else {
			this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
				this.doSearchFilterSellers();
			});
		}
	}

	// --------------------- When filter/search/pagination events trigger --------------------- //

	//Pagination
	onSelectPageNumber = (name) => (event) => {
		this.setState({ pageNumValue: event }, () => {
			this.doChangeUrlWithQuery();
		});
	};

	// Filter
	onChangeFilter = (name) => (event) => {
		this.setState(
			{
				filterValue: event,
				pageNumValue: 1,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};

	// Search
	onChangeSearch(search) {
		this.setState(
			{
				searchValue: search,
				pageNumValue: 1,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	}

	// Change URL based on filter/search/Page values
	doChangeUrlWithQuery() {
		let searchCriteria = {
			Filter: this.state.filterValue,
			Search: this.state.searchValue,
			ArtistName: this.state.artistName,
			ArtistId: this.state.selectedArtists,
			MinPrice: this.state.minPrice,
			MaxPrice: this.state.maxPrice,
			MinOffer: this.state.minOffer,
			MaxOffer: this.state.maxOffer,
			MinOfferPrice: this.state.minOfferPrice,
			MaxOfferPrice: this.state.maxOfferPrice,
			IsFeatured: this.state.IsFeatured ? true : false,
			SortOption: this.state.orderOption,
			SortOrder: this.state.orderSort,
			Liaison: this.state.selectedLiaison,
		};
		const queryString = Util.queryStringHandling(searchCriteria, "STR");
		localStorage.setItem("artworkLastUrl", "/manage-artworks/" + this.state.pageNumValue + "?" + queryString);
		this.props.history.push("/manage-artworks/" + this.state.pageNumValue + "?" + queryString);
	}

	//-------------Dialog for delete artwork---------------------//
	toggleMintTokenConfirmationDialog = (item) => {
		this.setState(
			{
				mintTokenconfirmationDialog: !this.state.mintTokenconfirmationDialog,
			},
			() => {
				this.setState({
					artworkId: item._id,
				});
			}
		);
	};

	//-------------Dialog for UnHold artwork---------------------//
	toggleUnHoldConfirmationDialog = (item) => {
		this.setState(
			{
				unHoldDialog: !this.state.unHoldDialog,
			},
			() => {
				this.setState({
					stockId: item.stockId,
					artworkId: item._id
				});
			}
		);
	};

	//-------------Dialog for Hold artwork---------------------//
	toggleHoldConfirmationDialog = (item, status) => {
		this.setState(
			{
				holdDialog: !this.state.holdDialog,
			},
			() => {
				this.setState({
					artworkId: item._id,
					status: status
				});
			}
		);
	};

	async onMintToken(item) {
		let artworkId = this.state.artworkId;
		await submitArtworktoBlockchain(artworkId, null);
		this.handleReset();
		/* reload the page after api call */
		let requestObj = localStorage.getItem("artworkFilterOptions") !== undefined ? JSON.parse(localStorage.getItem("artworkFilterOptions")) : [];
		this.props.getArtworksListAction(requestObj);
	}
	stringToArray(arr) {
		return isArray(arr) ? arr : [...[], arr];
	}
	// ----------------------- API - Fetch Sellers ----------------------------- //
	doSearchFilterSellers() {
		// POST request body
		if (this.state.filterValue) {
			if (!this.state.orderSort && !this.state.orderOption) {
				let request = {
					PageNum: this.state.pageNumValue,
					PageSize: 10,
					ProductName: this.state.searchValue || "",
					ArtistIds: this.state.selectedArtists || [],
					MinPrice: this.state.minPrice || 1,
					MaxPrice: this.state.maxPrice || 9999999,
					OfferMinPercentage: this.state.minOffer || 0,
					OfferMaxPercentage: this.state.maxOffer || 100,
					OfferMinAmount: this.state.minOfferPrice || 0,
					OfferMaxAmount: this.state.maxOfferPrice || 9999999,
					ArtistName: this.state.artistName || "",
					IsFeatured: this.state.IsFeatured ? true : null,
					Liaison: this.state.selectedLiaison || null,
				};

				let finalObj = {};
				if (this.state.filterValue === "6") {
					finalObj = {
						...request,
						SubStatus: [1],
					};
				} else {
					finalObj = {
						...request,
						Status: isArray(this.state.filterValue) ? this.state.filterValue : [this.state.filterValue],
					};
				}
				this.props.getArtworksListAction(finalObj);
			} else {
				let request = {
					PageNum: this.state.pageNumValue,
					PageSize: 10,
					// Status: [this.state.filterValue],
					ProductName: this.state.searchValue || "",
					SortOrder: this.state.orderSort,
					SortBy: this.state.orderOption,
					ArtistIds: this.state.selectedArtists || [],
					MinPrice: this.state.minPrice || 1,
					MaxPrice: this.state.maxPrice || 9999999,
					OfferMinPercentage: this.state.minOffer || 0,
					OfferMaxPercentage: this.state.maxOffer || 100,
					OfferMinAmount: this.state.minOfferPrice || 0,
					OfferMaxAmount: this.state.maxOfferPrice || 9999999,
					ArtistName: this.state.artistName || "",
					IsFeatured: this.state.IsFeatured ? true : null,
					Liaison: this.state.selectedLiaison || null,
				};
				let finalObj = {};
				if (this.state.filterValue === "6") {
					finalObj = {
						...request,
						SubStatus: [this.state.filterValue],
					};
				} else {
					finalObj = {
						...request,
						Status: isArray(this.state.filterValue) ? this.state.filterValue : [this.state.filterValue],
					};
				}
				this.props.getArtworksListAction(finalObj);
			}
		} else {
			if (!this.state.orderSort && !this.state.orderOption) {
				let request = {
					PageNum: this.state.pageNumValue,
					PageSize: 10,
					ProductName: this.state.searchValue || "",
					ArtistIds: this.state.selectedArtists || [],
					MinPrice: this.state.minPrice || 1,
					MaxPrice: this.state.maxPrice || 9999999,
					OfferMinPercentage: this.state.minOffer || 0,
					OfferMaxPercentage: this.state.maxOffer || 100,
					OfferMinAmount: this.state.minOfferPrice || 0,
					OfferMaxAmount: this.state.maxOfferPrice || 9999999,
					ArtistName: this.state.artistName || "",
					IsFeatured: this.state.IsFeatured ? true : null,
					Liaison: this.state.selectedLiaison || null,
				};
				this.props.getArtworksListAction(request);
			} else {
				let request = {
					PageNum: this.state.pageNumValue,
					PageSize: 10,
					ProductName: this.state.searchValue || "",
					SortOrder: this.state.orderSort,
					SortBy: this.state.orderOption,
					ArtistIds: this.state.selectedArtists || [],
					MinPrice: this.state.minPrice || 1,
					MaxPrice: this.state.maxPrice || 9999999,
					OfferMinPercentage: this.state.minOffer || 0,
					OfferMaxPercentage: this.state.maxOffer || 100,
					OfferMinAmount: this.state.minOfferPrice || 0,
					OfferMaxAmount: this.state.maxOfferPrice || 9999999,
					ArtistName: this.state.artistName || "",
					IsFeatured: this.state.IsFeatured ? true : null,
					Liaison: this.state.selectedLiaison || null,
				};
				this.props.getArtworksListAction(request);
				
			}
		}
	}

	//----------------Dialog for approve artwork-----------------//
	toggleViewEditionTable(item) {
		this.setState(
			{
				showEditionListTable: true,
			},
			() => {
				this.setState({
					editionArtworkId: item._id
				});
			}
		);
	}

	toggleHideEditionTable(item) {
		this.setState(
			{
				showEditionListTable: false,
			},
			() => {
				this.setState({
					editionArtworkId: ""
				});
			}
		);
	}


	//----------------Dialog for approve artwork-----------------//
	toggleApproveConfirmationDialog(item, status) {
		this.setState(
			{
				approveconfirmationDialog: !this.state.approveconfirmationDialog,
			},
			() => {
				this.setState({
					artworkId: item._id,
					status: status,
				});
			}
		);
	}

	//----------------Dialog for reserve for auction-----------------//
	toggleReserveForAuctionConfirmationDialog(item, status) {
		this.setState(
			{
				markAsReserveAuctionconfirmationDialog: !this.state.markAsReserveAuctionconfirmationDialog,
			},
			() => {
				this.setState({
					artworkId: item._id,
					status: status,
				});
			}
		);
	}

	//-------------Dialog for reject artwork---------------------//
	toggleRejectConfirmationDialog(item, status) {
		this.setState(
			{
				rejectconfirmationDialog: !this.state.rejectconfirmationDialog,
			},
			() => {
				this.setState({
					status: status,
					artworkId: item._id,
				});
			}
		);
	}

	//-------------Dialog for Mark As Sold---------------------//
	toggleMarkAsSoldConfirmationDialog(item, status) {
		this.setState(
			{
				markAsSoldDialog: !this.state.markAsSoldDialog,
			},
			() => {
				this.setState({
					currentData: item,
					artworkId: item._id,
					status: status,
				});
			}
		);
	}

	//-------------Dialog for delete artwork---------------------//
	toggleDeleteConfirmationDialog(item, status) {
		this.setState(
			{
				deleteconfirmationDialog: !this.state.deleteconfirmationDialog,
			},
			() => {
				this.setState({
					artworkId: item._id,
					status: status
				});
			}
		);
	}

	//-------------get artist dropdown names---------------------//
	async getArtistNames() {

		getArtistNamesAction().then(resp => {
			if (resp && resp.data) {
				this.setState({
					artistsList: resp.data.artistList
				})
			}
		});


	}

	//-------------Approve Artwork Confirm---------------------//
	async onApproveArtwork() {
		let request = {
			_id: this.state.artworkId,
			status: this.state.status,
		};
		modifyArtworkStateAction(request).then(resp => {
			this.doSearchFilterSellers();
			this.handleReset();
		});


	}

	async onClickReserveArtworkForAuction() {
		let request = {
			_id: this.state.artworkId,
			status: 16,
		};
		modifyArtworkStateAction(request).then(resp => {
			this.doSearchFilterSellers();
			this.handleReset();
		});
	}

	//-------------Block Artwork Confirm---------------------//
	async onRejectArtwork() {
		let request = {
			_id: this.state.artworkId,
			status: this.state.status,
		};
		modifyArtworkStateAction(request).then(resp => {
			this.doSearchFilterSellers();
			this.handleReset();
		});
		// blockArtworkOriginal(this.state.artworkId, "").then(resp => {
		// 	this.handleReset();
		// }).catch(err => {
		// 	this.handleReset();
		// });
	}

	//-------------Update Product Status---------------------//
	async onUpdateArtworkStatus() {
		let request = {
			_id: this.state.artworkId,
			status: this.state.status,
		};
		modifyArtworkStateAction(request).then(resp => {
			this.doSearchFilterSellers();
			this.handleReset();
		});
	}

	//-------------Update Product Status---------------------//
	async onUnHoldArtwork() {
		unHoldArtworkAction(this.state.artworkId, "").then(resp => {
			this.doSearchFilterSellers();
			this.handleReset();
		}).catch(err => {
			this.doSearchFilterSellers();
			this.handleReset();
		})

	}

	//-------------Delete Artwork Confirm---------------------//
	async onDeleteArtwork() {
		let request = {
			_id: this.state.artworkId,
			status: this.state.status,
		};
		modifyArtworkStateAction(request).then(resp => {
			this.doSearchFilterSellers();
			this.handleReset();
		});
		// await this.props.deleteArtworkAction(this.state.stockId);
		// await this.doSearchFilterSellers();
		// await this.handleReset();
	}

	onProductView(slugId) {
		let newPageUrl = "https://rtistiq.bluecast.tech/artworks/en/" + slugId;
		window.open(newPageUrl, "_blank");
	}
	//Reset fields
	handleReset() {
		this.setState({
			...this.state,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			deleteconfirmationDialog: false,
			mintTokenconfirmationDialog: false,
			markAsSoldDialog: false,
			unHoldDialog: false,
			stockId: "",
			status: null,
			currentData: null,
			artworkId: "",
			holdDialog: false,
		});
	}

	// ----------------------- Edit Artwork ------------------------ //
	onVisibleChangeOfArtworkRecord = (data, visibility) => {
		this.setState({
			selectedRecordToPerformAction: visibility ? data : null,
		});
	};

	onClickEditArtwork = () => {
		const { selectedRecordToPerformAction } = this.state;
		if (selectedRecordToPerformAction && selectedRecordToPerformAction.slugId) {
			if (selectedRecordToPerformAction.artworkUpdateStatus === 1) {
				this.props.history.push({
					pathname: `/edit-artwork-metadata/${selectedRecordToPerformAction.slugId}/${selectedRecordToPerformAction.stockId}/${this.state.pageNumValue}`,
				});
			} else {
				this.props.history.push({
					pathname: `/edit-artwork/${selectedRecordToPerformAction.slugId}/${selectedRecordToPerformAction.stockId}/${this.state.pageNumValue}`,
				});
			}
		}
	};
	//==================Download Artworks=======================//
	downloadArtworks = () => {
		if (this.state.filterValue) {
			if (!this.state.orderSort && !this.state.orderOption) {
				let request = {
					PageNum: 0,
					PageSize: 0,
					Status: isArray(this.state.filterValue) ? this.state.filterValue : [this.state.filterValue],
					ProductName: this.state.searchValue || "",
				};
				this.props.adminDownloadProductAction(request);
			} else {
				let request = {
					PageNum: 0,
					PageSize: 0,
					Status: isArray(this.state.filterValue) ? this.state.filterValue : [this.state.filterValue],
					ProductName: this.state.searchValue || "",
					SortOrder: this.state.orderSort,
					SortBy: this.state.orderOption,
				};
				this.props.adminDownloadProductAction(request);
			}
		} else {
			if (!this.state.orderSort && !this.state.orderOption) {
				let request = {
					PageNum: 0,
					PageSize: 0,
					ProductName: this.state.searchValue || "",
				};
				this.props.adminDownloadProductAction(request);
			} else {
				let request = {
					PageNum: 0,
					PageSize: 0,
					ProductName: this.state.searchValue || "",
					SortOrder: this.state.orderSort,
					SortBy: this.state.orderOption,
				};
				this.props.adminDownloadProductAction(request);
			}
		}
	};
	//Set Featured Artwork
	async onFeatured(item) {
		await this.props.setArtworkFeaturedAction(item._id);
		await this.doSearchFilterSellers();
	}
	//Set UnFeatured Artwork
	async onUnFeatured(item) {
		await this.props.setArtworkFeaturedAction(item._id);
		await this.doSearchFilterSellers();
	}
}

const mapStateToProps = (state) => {
	return {
		manageArtworksReducer: state.manageArtworksReducer,
		collectionReducer: state.collectionReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getArtworksListAction: (inputData) => {
			dispatch(getArtworksListAction(inputData));
		},
		updateProductStatusAction: (updateData, inputData) => {
			dispatch(updateProductStatusAction(updateData, inputData));
		},

		deleteArtworkAction: (stockId) => {
			dispatch(deleteArtworkAction(stockId));
		},
		adminDownloadProductAction: (body) => {
			dispatch(adminDownloadProductAction(body));
		},
		setArtworkFeaturedAction: (artworkId) => {
			dispatch(setArtworkFeaturedAction(artworkId));
		},
		getAllArtists: () => {
			dispatch(getAllArtists());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageArtWorks);
