import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
// Redux
import { connect } from "react-redux";
// SCSS
import "./Style.scss";
import { popToast } from "../../components/Toast/Toast";

//Files
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
import { uploadBulkArtwork, downloadBulkuploadForm, getArtistNamesAction } from "../../redux/actions/ManageArtworksAction";


class BulkUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collectionName: "",
            slugId: "",
            description: "",
            uploadFile: '',
            selectedArtist: "",
            dowloadBulkFormLoader: false,
            artistsList: []

        };
    }

    componentDidUpdate(prevProps, prevState) {

    }
    componentDidMount() {
        this.getArtistNames();
    }

    //-------------get artist dropdown names---------------------//
    async getArtistNames() {

        getArtistNamesAction().then(resp => {
            if (resp && resp.data) {
                this.setState({
                    artistsList: resp.data.artistList
                })
            }
        });


    }


    onClickUpload() {
        if (this.state.selectedArtist === "") {
            popToast.show("Please choose one artist");
        } else if (this.state.uploadFile === '') {
            popToast.show("Please upload excel file");
        } else {
            let formbody = {
                Excel: this.state.uploadFile,
                OwnerId: this.state.selectedArtist
            };
            const formData = Util.objectToFormData(formbody)
            this.props.uploadBulkArtwork(formData)
        }


    }

    async onClickDownLoadBulfForm() {
        this.setState({
            dowloadBulkFormLoader: true
        })
        await downloadBulkuploadForm();
        this.setState({
            dowloadBulkFormLoader: false
        })
    }
    onChangeArtist(e) {
        this.setState(
            {
                selectedArtist: e.target.value,

            });
    };
    onFileChange(file) {
        this.setState({
            uploadFile: file
        })
    }
    render() {
        const { bulkUploadData } = this.props.productReducer
        console.log("🚀 ~ file: BulkUpload.js ~ line 70 ~ BulkUpload ~ render ~ bulkUploadData", bulkUploadData)
        return (
            <Root>
                <Topbar title={"Bulk Artwork Upload"} />


                <div className="bulk-main-container">
                    <div className="form-control">
                        <div className="add-action">
                            <button className="btn-orange" onClick={this.onClickDownLoadBulfForm.bind(this)} disabled={this.state.dowloadBulkForm}>
                                Download Bulk Form
                                {this.state.dowloadBulkFormLoader && <CircularProgress size={24} className="circular-loader btn-color" />}
                            </button>
                        </div>
                    </div>

                    <div className="form-control">
                        <label className="form-label">Select Artist</label>
                        <select className="form-input" onChange={this.onChangeArtist.bind(this)}>
                            <option value="">Choose Artist</option>
                            {this.state.artistsList && this.state.artistsList.map((data, index) =>
                                <option value={data.userId}>{data.alaisName}</option>
                            )}

                        </select>

                    </div>
                    <div className="form-control">
                        <input type="file" onChange={e => {
                            this.onFileChange(e.target.files[0])
                            console.log("file", e.target.files[0])
                        }} />

                    </div>
                    <div className="add-action">

                        <button className="btn-orange" onClick={this.onClickUpload.bind(this)} disabled={this.props.productReducer.isUploadProgress}>
                            Upload
                            {this.props.productReducer.isUploadProgress && <CircularProgress size={24} className="circular-loader btn-color" />}
                        </button>
                    </div>
                    <ul className="error-list">

                        {bulkUploadData && bulkUploadData.length > 0 && bulkUploadData.map((data, index) =>
                            <li key={index}><span>{data}</span></li>
                        )}


                    </ul>


                </div>






            </Root>


        )
    }





}

const mapStateToProps = state => {
    return {
        collectionReducer: state.collectionReducer,
        productReducer: state.manageArtworksReducer,

    }

};

const mapDispatchToProps = dispatch => {
    return {

        uploadBulkArtwork: (body) => {
            dispatch(uploadBulkArtwork(body))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BulkUpload);
