import React, { useState, useEffect, useCallback } from "react";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import useSurveyForm from "./useSurveyForm";
import { isEmpty } from "lodash";
// import { StylesManager, Model } from "survey-core";
// import { Survey } from "survey-react-ui";
// import "survey-core/defaultV2.min.css";
// StylesManager.applyTheme("defaultV2");

function PreviewSurvey({ match }) {
	const [survey, setSurvey] = useState({});
	const {
		//Function
		getSurveyById,
		//Varables
		singleSurvey,
	} = useSurveyForm();

	//Survey Obj
	// const surveyModal = new Model(survey);
	// surveyModal.focusFirstQuestionAutomatic = false;

	useEffect(() => {
		if (match && match.params && match.params.surveyId) {
			getSurveyById(match.params.surveyId);
		}
	}, []);

	useEffect(() => {
		if (!isEmpty(singleSurvey)) {
			let elements = singleSurvey && singleSurvey.pages && singleSurvey.pages[0] && singleSurvey.pages[0].elements;
			let surveyJsonContent = {
				title: singleSurvey.surveyTitle,
				description: singleSurvey.surveySubTitle,
				logoPosition: "right",
				pages: [
					{
						name: "page1",
						elements: elements,
					},
				],
			};
			setSurvey(surveyJsonContent);
		}
	}, [singleSurvey]);

	const alertResults = useCallback((sender) => {
		const results = JSON.stringify(sender.data);
	}, []);
	// surveyModal.onComplete.add(alertResults);

	return (
		<Root>
			{/* <div className="survey-main-wrapper">{!isEmpty(survey) && <Survey model={surveyModal} />}</div> */}
		</Root>
	);
}

export default PreviewSurvey;
